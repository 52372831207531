/**
 * @generated SignedSource<<4923607bd30b39daa53af74837cbc0f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ContentType = "assignment" | "assignment_submission" | "attachment" | "comment" | "confirmation" | "custom" | "embed" | "module" | "occurrence" | "post" | "quiz" | "survey" | "text" | "video" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ContentModuleListItemFragment$data = {
  readonly content: {
    readonly children: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly hasPrerequisites: boolean;
          readonly id: string;
          readonly isLocked: boolean;
        };
      }>;
      readonly totalCount: number;
    } | null;
    readonly description: string | null;
    readonly id: string;
    readonly name: string | null;
    readonly type: ContentType;
    readonly viewerChildContentCompletions: {
      readonly totalCount: number;
    };
  };
  readonly hasPrerequisites: boolean;
  readonly id: string;
  readonly isLocked: boolean;
  readonly occurrences: {
    readonly totalCount: number;
  };
  readonly releasedAt: string | null;
  readonly viewerHasCompleted: boolean;
  readonly " $fragmentSpreads": FragmentRefs<"ContentModuleAdminDropdownFragment" | "ContentModuleEvents_ContentUsageFragment" | "ContentModuleReleaseCountdownFragment" | "ContentModuleReleaseDateFragment" | "ContentUsageLockIconFragment" | "CreateContentModuleItemButtonFragment" | "usePermissionsFragment">;
  readonly " $fragmentType": "ContentModuleListItemFragment";
};
export type ContentModuleListItemFragment$key = {
  readonly " $data"?: ContentModuleListItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContentModuleListItemFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isLocked",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasPrerequisites",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "contentLabelIds"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContentModuleListItemFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "releasedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerHasCompleted",
      "storageKey": null
    },
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "contentLabelIds",
              "variableName": "contentLabelIds"
            }
          ],
          "concreteType": "ContentUsageNodeConnection",
          "kind": "LinkedField",
          "name": "children",
          "plural": false,
          "selections": [
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "ContentUsageNodeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ContentUsage",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    (v1/*: any*/),
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ContentCompletionNodeConnection",
          "kind": "LinkedField",
          "name": "viewerChildContentCompletions",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "OccurrenceNodeConnection",
      "kind": "LinkedField",
      "name": "occurrences",
      "plural": false,
      "selections": (v4/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateContentModuleItemButtonFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentModuleReleaseCountdownFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentModuleReleaseDateFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentModuleAdminDropdownFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "usePermissionsFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentModuleEvents_ContentUsageFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ContentUsageLockIconFragment"
    }
  ],
  "type": "ContentUsage",
  "abstractKey": null
};
})();

(node as any).hash = "41fa5bc74ca2d680e7786f1263096f55";

export default node;
